import React from 'react';

import Layout from '../components/layout/Layout';
import SEO from '../components/Seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>
      Page not found. Sad times
      <span role="img" aria-label="Sad"> 😰</span>
      .
    </h1>
  </Layout>
);

export default NotFoundPage;
